import { getBaseUrl } from '../../../utils/url'

const archOgImage =
  'https://s3.amazonaws.com/iris-opengraph-images/ImgTag_Architecture.jpg'
const constOgImage =
  'https://s3.amazonaws.com/iris-opengraph-images/ImgTag_Construction.jpg'
const engOgImg =
  'https://s3.amazonaws.com/iris-opengraph-images/ImgTag_Engg.jpg'
const studentOgImage =
  'https://s3.amazonaws.com/iris-opengraph-images/ImgTag_Students.jpg'
const eduOgImage =
  'https://s3.amazonaws.com/iris-opengraph-images/ImgTag_Edu.jpg'

export default {
  architecture: {
    meta: {
      title: 'Virtual Reality (VR) for Architecture | IrisVR',
      desc:
        'Immersive software for virtual reality (VR) for architecture industry. Use SketchUp, Revit, Rhino 3D models and panoramas, perform walkthroughs of unbuilt spaces.',
      image: archOgImage,
    },
    main: {
      title: 'Virtual Reality for Architecture',
      subtitle:
        'Instantly Make Your Design Concept Come to Life in A True-To-Scale Environment',
      desc:
        'IrisVR works with 3D software you already use to give a true sense of depth and space before anything has been built.',
    },
    1: {
      title: 'Present design options to clients in an immersive walkthrough',
      subtitle:
        'Break down communication barriers and go beyond 2D drawings that leave room for misunderstanding. VR brings everyone into the same 3D model and onto the same page.',
      imgAlt: 'arch-img-1',
    },
    2: {
      title: 'Experience unbuilt spaces before breaking ground',
      subtitle:
        'Reduce the time, money, and materials spent building physical mockups.',
      imgAlt: 'arch-img-2',
    },
    3: {
      title: 'Continue to use your existing 3D workflow',
      subtitle:
        'You already use the software needed to create virtual reality experiences with IrisVR. Prospect works with your SketchUp, Revit, Rhino, Grasshopper, and OBJ files to create immersive VR walkthroughs.',
      imgAlt: 'arch-img-3',
    },
    4: {
      title: 'Streamline project reviews and receive actionable feedback',
      subtitle:
        'Whether you’re in the schematic design phase or starting the building process, make your vision clear to stakeholders at every phase of the design process.',
      imgAlt: 'arch-img-4',
    },
  },
  engineering: {
    meta: {
      title: 'Virtual Reality (VR) for Engineering | IrisVR',
      desc:
        'Immersive virtual reality software for the engineering industry. Use Navisworks, Revit, BIM, Rhino, Grasshopper files; perform VR walkthroughs of 3D models.',
      image: engOgImg,
    },
    main: {
      title: 'Virtual Reality for Engineering',
      subtitle: 'VR for the entire AEC project team',
      desc:
        'IrisVR works with 3D software you already use to give a true sense of depth and space before anything has been built, down to the smallest detail.',
    },
    1: {
      title: 'Review complex details in an immersive, data-rich environment',
      subtitle:
        'Explore high fidelity models and view difficult areas with ease in a true-to-scale environment.',
      imgAlt: 'edu-img-1',
    },
    2: {
      title: 'Gain a competitive edge and produce superior quality submittals',
      subtitle:
        'Go beyond a plotted sheet and get insight into how your team would build, design, or manage a project with VR walkthroughs.',
      imgAlt: 'edu-img-2',
    },
    3: {
      title:
        'Catch conflicts in pre-construction, saving time and reducing costs',
      subtitle: 'Use VR to review project plans before breaking ground.',
      imgAlt: 'edu-img-3',
    },
    4: {
      title:
        'Visually explain details to your on-site team before construction starts',
      subtitle:
        'Get buy-in from all project stakeholders, even those who struggle to understand the project when viewing plans.',
      imgAlt: 'edu-img-4',
    },
  },
  construction: {
    meta: {
      title: 'Virtual Reality (VR) for Construction | IrisVR',
      desc:
        'Immersive software for virtual reality (VR) for the construction industry. Use Navisworks, Revit, BIM files, to perform a walkthrough of your 3D models.',
      image: constOgImage,
    },
    main: {
      title: 'Virtual Reality for Construction',
      subtitle: 'Collaborate Like Never Before with VR',
      desc:
        'IrisVR works with the 3D software you and your team already use to provide a true-to-scale preview of a project before breaking ground.',
    },
    1: {
      title:
        'Resolve construction conflicts virtually to keep projects on budget',
      subtitle:
        'On a tight budget? Reduce extra meetings and streamline coordination with immersive VR project reviews. Our tools leave nothing to the imagination and bring all stakeholders onto the same page.',
      imgAlt: 'const-img-1',
    },
    2: {
      title:
        'Run QA and QC from BIM in VR to improve review and RFI efficiency',
      subtitle:
        'Performing QA/QC has never been easier. Walk through the pre-built space to quickly identify clashes, concerns and items needing refinement.',
      imgAlt: 'const-img-2',
    },
    3: {
      title:
        'Increase safety and reduce on-site risks by speaking a common language',
      subtitle:
        'Regardless of skill level or technical training, your whole team will be able to communicate seamlessly using virtual reality.',
      imgAlt: 'const-img-3',
    },
    4: {
      title: 'Simulate sequencing and evaluate multiple scenarios',
      subtitle:
        'During pre-construction meetings, communicate design intent to your team in an intuitive environment.',
      imgAlt: 'const-img-4',
    },
  },
  educators: {
    meta: {
      title: 'Virtual Reality (VR) for Educators | IrisVR',
      desc:
        'Educators - use VR to perform an immersive walkthrough of 3D models or view 360 panoramas! Compatible with many Architecture industry file types.',
      image: eduOgImage,
    },
    main: {
      title: 'Virtual Reality for Educators',
      subtitle: 'Give Your Students The Tools To Succeed',
      desc:
        'IrisVR works with 3D software you already use to give your students a true sense of the depth and space in their projects.',
    },
    1: {
      title:
        'Demonstrate the spatial ramifications of design studies with one click.',
      imgAlt: 'edu-img-1',
    },
    2: {
      title:
        'Teach students to create multimedia pin-ups that engage their audience & wow the judges.',
      imgAlt: 'edu-img-2',
    },
    3: {
      title:
        'Give your students specialized 3D skills in a competitive job market.',
      imgAlt: 'edu-img-3',
    },
  },
  students: {
    meta: {
      title: 'Virtual Reality (VR) for Students | IrisVR',
      desc:
        'Students - use VR to perform an immersive walkthrough of your 3D model or view 360 panoramas! Compatible with many Architecture industry file types.',
      image: studentOgImage,
    },
    main: {
      title: 'Virtual Reality for Students',
      subtitle:
        'Understand Design At Human Scale From Concept Through Presentation.',
      desc:
        'IrisVR works with 3D software you already use to give a true sense of depth and space in your designs.',
    },
    1: {
      title:
        'Build a specialized skill set for a competitive job market using VR',
      imgAlt: 'student-img-1',
    },
    2: {
      title:
        'Test out your concepts quickly in an immersive, true-to-scale environment',
      imgAlt: 'student-img-2',
    },
    3: {
      title:
        'Work with SketchUp, Revit, Rhino, Grasshopper, and .OBJ seamlessly',
      imgAlt: 'student-img-3',
    },
  },
}
